import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log(1111);
    $('body').append(this.renderHtmlModal())
  }

  onConfirm(e) {
    e.preventDefault();
    const $modal = $('#js-modal-confirm-delete')
    let $link = $(e.target)
    if (! $(e.target).is('a')) {
      $link = $(e.target).parents('a')
    }

    $modal.find('a[data-method="delete"]').prop('href', $link.prop('href'))
    $modal.find('[aria-label="title"]').html($link.data('confirmTitle'))
    $modal.find('[aria-label="message"]').html($link.data('confirmMessage'))
    $modal.modal('show')
  }

  renderHtmlModal() {
    let html = `
      <div aria-hidden="true" class="modal modal-blur fade" id="js-modal-confirm-delete" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h3 class="text-center">
                Delete
              </h3>
              <h4 class="js-show-information">
              </h4>
              <div class="modal-title">
                Are you sure you wan to delete ?
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary text-white link-secondary me-auto js-modal-cancel" type="button">
                Cancel
              </button>
          </div>
        </div>
      </div>
    `
    return html;
  }
}
