require.context("../images", true, /\.(png|jpg|jpeg|svg|ico)$/);

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("controllers/admin_user")
require("trix")
require("@rails/actiontext")

import "@tabler/core";

import jquery from "jquery";

import { I18n } from "i18n-js";
import translations from "../../../public/javascripts/translations.json"
window.I18n = new I18n();
window.I18n.store(translations);

import "@fortawesome/fontawesome-free/css/all";
import '../stylesheets/application.scss';

window.$ = window.jquery = jquery;

import toastr from "toastr";
window.toastr = toastr;

toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-bottom-left",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

// Page before cache: remove duplicates select2
$(document).on("turbolinks:before-cache", function() {
  $('.select2-hidden-accessible').select2("destroy");
});
