import { Controller } from "stimulus"

export default class extends Controller {
  // connect() {
  //   console.log('👋 Say hello!');
  // }

  onToggle(e) {
    const $this = $(e.target)
    const $input = $this.parents('.input-group').find('input')

    if ($input.prop('type') === "password") {
      $input.prop('type', 'text')
      $this.removeClass('fa-eye').addClass('fa-eye-slash')
    } else {
      $input.prop('type', 'password')
      $this.removeClass('fa-eye-slash').addClass('fa-eye')
    }
  }
}
