import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "dropdown" ]

  connect() {
    Object.entries(this.dropdownTargets).forEach((el) => {
      el[1].querySelectorAll('a').forEach((item) => {
        if (document.location.pathname.match(item.pathname) !== null) {
          item.closest('.dropdown-menu').classList.add('show');
        };
      });
    });
  }
}
