import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["previewImages"];

  onConfirm(e) {
    e.preventDefault();
    $('#js-consultant-modal-confirm-delete').modal('show');
    $('.js-delete-url').attr('href', e.currentTarget.href)
  }

  deleteImage({ params, currentTarget }){
    const object = params.object || 'job'
    const html_str = '<input class="form-control hidden" value="'+params.key+'" autocomplete="off" type="hidden" name="'+object+'[purge_image]" id="'+object+'_purge_image' +'">'
    $(this.previewImagesTarget).append(html_str)
    $(currentTarget).parent().parent().remove()
  }
}
