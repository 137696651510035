import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.querySelectorAll(".js-candidate").forEach((item) => {
      if (document.location.pathname.match(item.pathname) !== null) {
        item.classList.add('active');
        return;
      };
    });
  }
}
