import { Controller } from "stimulus";
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-5-theme/src/select2-bootstrap-5-theme")
import Select2 from "select2";
export default class extends Controller {
  static targets = [ "all" ]
  connect() {
    $('.js-select2-job-type, .js-select2-job-status, .js-select2-job-sort').select2({
      minimumResultsForSearch: 3,
      selectionCssClass: "form-select2",
      dropdownCssClass: "form-select2-result"
    });

    $('.js-select2-job-locations, .js-select2-job-occupations, .js-select2-job-features').select2({
      minimumResultsForSearch: 3,
      selectionCssClass: "form-select2",
      dropdownCssClass: "form-select2-result"
    });
    $('.js-select2-job-sort').select2({width: "element"})
    $('.js-select2-email').select2({
      minimumResultsForSearch: 3,
      selectionCssClass: "form-select2",
      dropdownCssClass: "form-select2-result",
      width: "style"
    })
    if (this.allTarget.checked) {
      $('.js-select2-email ~ .select2-container').hide()
      $('.js-select2-email ~ .invalid-feedback').hide()
    }
  };

  checked(e) {
    if (e.currentTarget.checked) {
      $('.js-select2-email ~ .select2-container').slideUp(222)
    }
  }

  unchecked(e) {
    if (e.currentTarget.checked) {
      $('.js-select2-email ~ .select2-container').slideDown(222)
    }
  }
}
