import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'alert' ]

  connect() {
    setTimeout(() => {
      $(this.alertTarget).slideUp();
    }, 3000)
  }
}
