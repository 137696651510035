import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'image' ]

  preview(e) {
    e.preventDefault();
    var tmp_path = window.URL.createObjectURL(e.target.files[0]);
    if (!tmp_path) return;
    this.imageTarget.setAttribute('src', tmp_path);
  }
}
